
import Vue from 'vue';
import Component from 'vue-class-component';
import { PropSync, Watch } from 'vue-property-decorator';

interface VExtType extends Vue {
  [k: string]: any;
}

@Component({})
export default class Signature extends Vue {
  @PropSync('signature') signatureSync: string;

  @PropSync('hasSignature') hasSignatureSync: boolean;

  $refs!: {
    signaturePad: VExtType;
  };

  options = {
    penColor: '#c0f',
  };

  start = false;

  onBegin(): void {
    this.$refs.signaturePad.resizeCanvas();
    this.start = true;
    this.hasSignatureSync = true;
  }

  onEnd(): void {
    const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

    if (!isEmpty) {
      this.signatureSync = data;
    }
  }
  // was trying to get the signature pad before it was mounted
  created() {
    this.$nextTick(() => {
      this.$refs.signaturePad.clearSignature();
    });
  }

  undo() {
    this.$refs.signaturePad.clearSignature();
    this.start = false;
    this.hasSignatureSync = false;
  }

  save(): void {
    const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

    if (!isEmpty) {
      this.signatureSync = data;
    }
  }
}
